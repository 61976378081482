import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Pages/Header/Header'; 
import Footer from './Pages/Footer/Footer';
import './App.css';
import RouteWrapper from './RouteWrapper';
import Loading from './Pages/Loading/Loading';
import Bookatable from './Pages/Bookatable/Bookatable';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import Search from './Search/Search';



const Home = lazy(('() => import./Pages/Home/HomeBody/Home'));
const About = lazy(() => import('./Pages/About/About'));
const Faq = lazy(() => import('./Pages/Faq/Faq'));
const Download = lazy(() => import('./Pages/Download/Download'));
const Location = lazy(() => import('./Pages/Location/Location'));
const HomePage = lazy(() => import('./Pages/Home/HomePage/HomePage'));
const MainManu = lazy(() => import('./Pages/Menu/Mainmanu/MainManu'));
const Service = lazy(() => import('./Pages/Service/Service'));
const Dessert = lazy(() => import('./Pages/New Manu/Dessert/DessertMain/Dessert'));
const PizzaDaValter = lazy(() => import('./Pages/New Manu/PizzaDaValter/PizzaDaValterMain/PizzaDaValter'));
const Drinks = lazy(() => import('./Pages/New Manu/Drinks/DrinksMain/Drinks'));
const PrivacyPolicy = lazy(() => import('./Pages/Footer/PrivacyPolicy'));

const App = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Router>
      {/* <Search/> */}
        <ScrollToTop />
      <Header />
      <div className='app-margin'></div>

      {loading && <Loading />}

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/home" element={<RouteWrapper element={<Home />} setLoading={setLoading} />} />
          <Route path="/about" element={<RouteWrapper element={<About />} setLoading={setLoading} />} />
          <Route path="/faq" element={<RouteWrapper element={<Faq />} setLoading={setLoading} />} />
          <Route path="/download" element={<RouteWrapper element={<Download />} setLoading={setLoading} />} />
          <Route path="/location" element={<RouteWrapper element={<Location />} setLoading={setLoading} />} />
          <Route path="/" element={<RouteWrapper element={<HomePage />} setLoading={setLoading} />} />
          <Route path="/menu" element={<RouteWrapper element={<MainManu />} setLoading={setLoading} />} />
          <Route path="/service" element={<RouteWrapper element={<Service />} setLoading={setLoading} />} />
          <Route path="/bookatable" element={<RouteWrapper element={<Bookatable/>} setLoading={setLoading} />} />
          {/* new manu */}
          <Route path="/dessert" element={<RouteWrapper element={<Dessert/>} setLoading={setLoading} />} />
          <Route path="/pizzadavalter" element={<RouteWrapper element={<PizzaDaValter/>} setLoading={setLoading} />} />
          <Route path="/drinks" element={<RouteWrapper element={<Drinks/>} setLoading={setLoading} />} />

          <Route path="/PrivacyPolicy" element={<RouteWrapper element={<PrivacyPolicy/>} setLoading={setLoading} />} />

        </Routes>
      </Suspense>
      <home/>
      <Footer />
    </Router>
  );
};

export default App;
