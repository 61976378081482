import pizzalogo from "../../../assets/Images/Home/PizzaLogo.png";

// Main_Salads
import Caproni_salad from  "../../../assets/NewMenu/PizzaDaValter/Main Salads/Caproni salad.jpg"
import CAPRESE from "../../../assets/NewMenu/PizzaDaValter/Main Salads/Caprese.jpeg"
import CHARGRILLED_VEGETABLES_AVOCADO from "../../../assets/NewMenu/PizzaDaValter/Main Salads/Chargrilled Vegetables & Avocad.jpeg"
import CHICKEN from "../../../assets/NewMenu/PizzaDaValter/Main Salads/chicken.jpeg"
// Main Courses
import Grilled_Chicken_Breast from  "../../../assets/NewMenu/PizzaDaValter/Main Courses/Grilled Chicken Breast.jpg"
import Salmone_Alla_Griglia from  "../../../assets/NewMenu/PizzaDaValter/Main Courses/Salmone Alla Griglia.jpg"
import Veal_Milanese from  "../../../assets/NewMenu/PizzaDaValter/Main Courses/Veal Milanese.jpg"
// Sides
import Mixed_Salad from  "../../../assets/NewMenu/PizzaDaValter/Sides/Mixed_Salad.jpeg"
import Rocket_and_Parmesan from  "../../../assets/NewMenu/PizzaDaValter/Sides/Rocket & Parmesan.jpg"
import  WILTED_BABY_SPINACH from "../../../assets/NewMenu/PizzaDaValter/Sides/Wilted Baby Spinach.jpeg"

// Juices
// From_the_Fridge
// import COKE from "../../../assets/NewMenu/Drinks/COKE.jpeg"
import FANTA from "../../../assets/NewMenu/Drinks/FANTA.jpeg"
import SPRITE from "../../../assets/NewMenu/Drinks/SPRITE.jpeg"
import CHINOTTO from "../../../assets/NewMenu/Drinks/CHINOTTO.jpeg"
import ARANCIATA from "../../../assets/NewMenu/Drinks/Aranciata.jpeg"
import LIMONATA from "../../../assets/NewMenu/Drinks/Limonata.jpeg"
import ARANCIATA_ROSSA from "../../../assets/NewMenu/Drinks/Aranciata_rossa.jpeg"

// Water
import STILL from '.././../../assets/NewMenu/PizzaDaValter/water/still.jpeg'
import PELLEGRINO from '.././../../assets/NewMenu/PizzaDaValter/water/SPARKLING.jpeg'
// Appetizers
import Arancini from  "../../../assets/NewMenu/PizzaDaValter/Appetizers/Arancini.jpg"
import CROQUETTES_FILLED_WITH_SCAMORZA from  "../../../assets/NewMenu/PizzaDaValter/Appetizers/CROQUETTES FILLED WITH SCAMORZA.jpg"
import GARLIC_BREAD from  "../../../assets/NewMenu/PizzaDaValter/Appetizers/GARLIC_BREAD.jpeg"
import SICILIAN_OLIVES from  "../../../assets/NewMenu/PizzaDaValter/Appetizers/Sicilian Olives.jpeg"


// Cold_Starters
import BRUSCHETTA_CLASSICA_Al_POMODORINI_DI_PACHINOzalogo from "../../../assets/NewMenu/PizzaDaValter/Cold Starters/BRUSCHETTA CLASSICA Al POMODORINI DI PACHINO.jpeg"
import BRUSCHETTA_CON_NDUJA_BURRATA from "../../../assets/NewMenu/PizzaDaValter/Cold Starters/BRUSCHETTA CON NDUJA & BURRATA.jpeg"

// Hot_Starters
import Calamari_Zucchine from "../../../assets/NewMenu/PizzaDaValter/Hot Starters/Calamari & Zucchine.jpg";
import Gamberoni_Pepe from "../../../assets/NewMenu/PizzaDaValter/Hot Starters/Gamberoni Al Pepe.jpg";
import Parmigiana from "../../../assets/NewMenu/PizzaDaValter/Hot Starters/Parmigiana.PNG";

// Pizza
import Diavola from "../../../assets/NewMenu/PizzaDaValter/Pizza/Diavola.jpg"
import Vegetariana from "../../../assets/NewMenu/PizzaDaValter/Pizza/Vegetariana.jpg"
import Calzone_Napoli from "../../../assets/NewMenu/PizzaDaValter/Pizza/Calzone Napoli.jpg"
import Napoleona from "../../../assets/NewMenu/PizzaDaValter/Pizza/Napoleona.PNG"
import pizzadavalter from "../../../assets/NewMenu/PizzaDaValter/Pizza/Pizza da valter.jpeg"
import la_meta from "../../../assets/NewMenu/PizzaDaValter/Pizza/La Meta Meta.jpeg"
import FORMAGGI from "../../../assets/NewMenu/PizzaDaValter/Pizza/4 FORMAGGI.jpeg" 
import BUFALINA from "../../../assets/NewMenu/PizzaDaValter/Pizza//BUFALINA.jpeg" 
import CAPRICCIOSA from "../../../assets/NewMenu/PizzaDaValter/Pizza/CAPRICCIOSA.jpeg"
import MARINARA from "../../../assets/NewMenu/PizzaDaValter/Pizza/MARINARA.jpeg"
import MARGHERITA from "../../../assets/NewMenu/PizzaDaValter/Pizza/MARGHERITA.jpeg"
import PROSCIUTTO_E_FUNGHI from "../../../assets/NewMenu/PizzaDaValter/Pizza/PROSCIUTTO E FUNGHI.jpeg"
import CALABRESE from "../../../assets/NewMenu/PizzaDaValter/Pizza/CALABRESE.jpeg"
import SALSICCIA from "../.././../assets/Images/Home/home1.png"

// Pasta
import Caproni_Penne from  "../../../assets/NewMenu/PizzaDaValter/Pasta/Caproni Penne.jpg"
import Lasagna_Bolognese from  "../../../assets/NewMenu/PizzaDaValter/Pasta/Lasagna Bolognese.jpg"
import Scialatielli_Alla_Carbonara from  "../../../assets/NewMenu/PizzaDaValter/Pasta/SCIALATIELLI ALLA CARBONARA.jpeg"
import Gnocchi_Alla_Sorrentina from  "../../../assets/NewMenu/PizzaDaValter/Pasta/Gnocchi Alla Sorrentina.jpg"
import Tagliatelle_Con_Ragu from  "../../../assets/NewMenu/PizzaDaValter/Pasta/Tagliatelle Con Ragu.jpg"
// Risotto
import Risotto_Pescatora from  "../../../assets/NewMenu/PizzaDaValter/Risotto/Risotto Pescatora.jpg"
import Risotto_Porcini_E_Carciofi from  "../../../assets/NewMenu/PizzaDaValter/Risotto/Risotto Porcini E Carciofi.jpg"

// Main_Courses
import PEPATA_DI_COZZE from "../../../assets/NewMenu/PizzaDaValter/Main Courses/PEPATA_DI_COZZE.jpeg"

const pizzalogoo ="https://fakeimg.pl/600x600?text=pizzadavalter";
const PizzaDaValterData = {
 
  Main_Salads: [
    // { 
    //   id: 1, 
    //   image: CAPRESE, 
    //   title: 'CAPRESE', 
    //   description: 'Buffalo mozzarella, plum tomatoes, basil, oregano, BlO EVO olive oil', 
    //   price: '£9.50' 
    // },
    { 
      id: 2, 
      image: CHARGRILLED_VEGETABLES_AVOCADO , 
      title: 'CHARGRILLED VEGETABLES & AVOCADO', 
      description: 'Asparagus, yellow & red peppers, aubergine, zucchini & avocado on a mix leaves salad, house dressing', 
      price: '£15.85' 
    },
    { 
      id: 3, 
      image: CHICKEN, 
      title: 'CHICKEN CAESAR', 
      description: 'Grilled chicken, croutons, pancetta, anchovies, capers & parmesan shavings on a romaine lettuce, salad & Caesar homemade dressing', 
      price: '£16.90' 
    },
    // { 
    //   id: 4, 
    //   image: pizzalogo, 
    //   title: 'SEAFOOD SALAD', 
    //   description: 'Octopus, clams, mussels, squids & king prawns on mixed salad leaves & anchovies dressing', 
    //   price: '£19.85' 
    // },
    // { 
    //   id: 5, 
    //   image: Caproni_salad, 
    //   title: 'Caproni Salad', 
    //   description: 'Grilled Goat cheese, toasted homemade bread, fresh mixed salad, black olives, cherry tomato, Sicilian salad dressing', 
    //   price: '£16.85' 
    // }
  ],
  Sides: [
    { 
      id: 1, 
      image: Mixed_Salad, 
      title: 'TOMATO MIXED SALAD', 
      description: '', 
      price: '£5.90' 
    },
    { 
      id: 1, 
      image: CAPRESE, 
      title: 'CAPRESE', 
      description: 'Buffalo mozzarella, plum tomatoes, basil, oregano, BlO EVO olive oil', 
      price: '£9.50' 
    },
    { 
      id: 2, 
      image: Rocket_and_Parmesan, 
      title: 'ROCKET & PARMESAN', 
      description: '', 
      price: '£6.90' 
    },
    { 
      id: 3, 
      image: pizzalogo, 
      title: 'AVOCADO MIXED SALAD', 
      description: '', 
      price: '£7.90' 
    },
    { 
      id: 4, 
      image: pizzalogo, 
      title: 'WILTED BABY SPINACH', 
      description: '', 
      price: '£6.90' 
    },
    { 
      id: 5, 
      image: pizzalogo, 
      title: 'CHIPS', 
      description: '', 
      price: '£4.50' 
    },
    { 
      id: 6, 
      image: pizzalogo, 
      title: 'MASHED POTATOES', 
      description: '', 
      price: '£4.90' 
    },
    { 
      id: 7, 
      image: WILTED_BABY_SPINACH, 
      title: 'FRIARIELLI & PATATE', 
      description: 'Napolitan wild broccoli & roasted new potatoes', 
      price: '£5.90' 
    }
  ],
  Juices: [
    { 
      id: 1, 
      image: pizzalogoo, 
      title: 'CARROT JUICE', 
      description: '', 
      price: '£6.00' 
    },
    
    { 
      id: 1, 
      image: pizzalogoo, 
      title: 'APPLE JUICE', 
      description: '', 
      price: '£6.00' 
    },
    { 
      id: 2, 
      image: pizzalogoo, 
      title: 'ORANGE JUICE', 
      description: '', 
      price: '£6.00'
    },
    { 
      id: 3, 
      image: pizzalogoo, 
      title: 'PINEAPPLE JUICE', 
      description: '', 
      price: '£5.00' 
    },
    // { 
    //   id: 4, 
    //   image: pizzalogo, 
    //   title: 'LYCHEE', 
    //   description: '', 
    //   price: '£4.75' 
    // },
    { 
      id: 5, 
      image: pizzalogoo, 
      title: 'CRANBERRY', 
      description: '', 
      price: '£5.00' 
    },
    { 
      id: 6, 
      image: pizzalogoo, 
      title: 'PASSION FRUIT', 
      description: '', 
      price: '£5.00' 
    }
  ],
  From_the_Fridge: [
    // { 
    //   id: 1, 
    //   image: pizzalogo, 
    //   title: 'POMPELMO', 
    //   description: '', 
    //   price: '£3.50' 
    // },
    { 
      id: 2, 
      image: CHINOTTO, 
      title: 'CHINOTTO', 
      description: '', 
      price: '£4.50' 
    },
    { 
      id: 3, 
      image: pizzalogoo, 
      title: 'COKE', 
      description: '', 
      price: '£4.50' 
    },
    // { 
    //   id: 4, 
    //   image: pizzalogo, 
    //   title: 'DIET COKE', 
    //   description: '', 
    //   price: '£3.70' 
    // },
    { 
      id: 5, 
      image: SPRITE, 
      title: 'SPRITE', 
      description: '', 
      price: '£4.50' 
    },
    { 
      id: 6, 
      image: FANTA, 
      title: 'FANTA', 
      description: '', 
      price: '£4.50'
    },
    // { 
    //   id: 7, 
    //   image: pizzalogo, 
    //   title: 'RED BULL', 
    //   description: '', 
    //   price: '£4.50' 
    // },
    { 
      id: 1, 
      image: pizzalogoo, 
      title: 'PANNA STILL WATER 500ML', 
      description: '', 
      price: '£4.50'
    },
    // { 
    //   id: 2, 
    //   image: STILL, 
    //   title: 'PANNA STILL WATER 750ML', 
    //   description: '', 
    //   price: '£4.90' 
    // },
    { 
      id: 3, 
      image: pizzalogoo, 
      title: 'SAN PELLEGRINO SPARKLING WATER 500ML', 
      description: '', 
      price: '£4.50'
    },
    // { 
    //   id: 4, 
    //   image: PELLEGRINO, 
    //   title: 'SAN PELLEGRINO SPARKLING WATER 750ML', 
    //   description: '', 
    //   price: '£4.90' 
    // }
    { 
      id: 7, 
      image: ARANCIATA, 
      title: 'ARANCIATA',
      description: 'SANPELLEGRINO', 
      price: '£4.50' 
    },
    { 
      id: 8, 
      image: LIMONATA, 
      title: 'LIMONATA', 
      description: 'SANPELLEGRINO', 
      price: '£4.50' 
    
    },
    { 
      id: 8, 
      image: ARANCIATA_ROSSA , 
      title: 'ARANCIATA ROSSA ', 
      description: 'SANPELLEGRINO', 
      price: '£4.50'
    },
    
  ],
  // Water: [
  //   { 
  //     id: 1, 
  //     image: pizzalogo, 
  //     title: 'PANNA STILL WATER 500ML', 
  //     description: '', 
  //     price: '£3.50' 
  //   },
  //   { 
  //     id: 2, 
  //     image: STILL, 
  //     title: 'PANNA STILL WATER 750ML', 
  //     description: '', 
  //     price: '£4.90' 
  //   },
  //   { 
  //     id: 3, 
  //     image: pizzalogo, 
  //     title: 'SAN PELLEGRINO SPARKLING WATER 500ML', 
  //     description: '', 
  //     price: '£3.50' 
  //   },
  //   { 
  //     id: 4, 
  //     image: PELLEGRINO, 
  //     title: 'SAN PELLEGRINO SPARKLING WATER 750ML', 
  //     description: '', 
  //     price: '£4.90' 
  //   }
  // ],
  Appetizers: [
    { 
      id: 1, 
      image: SICILIAN_OLIVES , 
      title: 'SICILIAN OLIVES (Nocellara)', 
      description: '', 
      price: '£3.90' 
    },
    { 
      id: 2, 
      image: pizzalogo, 
      title: 'BREAD BASKET', 
      description: '', 
      price: '£3.90' 
    },
    { 
      id: 3, 
      image: GARLIC_BREAD , 
      title: 'GARLIC BREAD', 
      description: '', 
      price: '£5.90' 
    },
    { 
      id: 4, 
      image: pizzalogo, 
      title: 'MELTED CHEESE BREAD', 
      description: '', 
      price: '£6.90' 
    },
    { 
      id: 5, 
      image: pizzalogo, 
      title: 'MONTANARE', 
      description: 'Pizza dough, tomato, parmesan & basil', 
      price: '£5.90' 
    },
    { 
      id: 6, 
      image: CROQUETTES_FILLED_WITH_SCAMORZA, 
      title: 'CROQUETTES FILLED WITH SCAMORZA', 
      description: '', 
      price: '£7.55' 
    },
    { 
      id: 7, 
      image: Arancini, 
      title: 'ARANCINI', 
      description: 'Rice balls filled with meat', 
      price: '£7.50' 
    }
  ],
  Cold_Starters: [
    { 
      id: 1, 
      image: BRUSCHETTA_CLASSICA_Al_POMODORINI_DI_PACHINOzalogo, 
      title: 'BRUSCHETTA CLASSICA Al POMODORINI DI PACHINO', 
      description: 'Sourdough bread, cherry tomatoes', 
      price: '£8.55' 
    },
    { 
      id: 2, 
      image:  BRUSCHETTA_CON_NDUJA_BURRATA, 
      title: 'BRUSCHETTA CON NDUJA & BURRATA', 
      description: 'Sourdough bread, cherry tomatoes, spicy salami (Nduja) topped with a burrata', 
      price: '£13.90' 
    },
    { 
      id: 3, 
      image: pizzalogo, 
      title: 'ANTIPASTO FREDDO', 
      description: 'Your choice of a burrata or a buffalo mozzarella on a bed of parma ham', 
      price: '£13.90' 
    },
    { 
      id: 4, 
      image: pizzalogo, 
      title: 'SELEZIONE DI SALUMI (2 PEOPLE)', 
      description: 'A selection of top quality cured meats, cheeses, olives & your choice of burrata or buffalo mozzarella', 
      price: '£19.90' 
    }
  ],
  Hot_Starters: [
    { 
      id: 1, 
      image: Parmigiana, 
      title: 'PARMIGIANA', 
      description: 'Layers of aubergine and tomato sauce, baked with parmesan & basil oil', 
      price: '£11.50' 
    },
    { 
      id: 2, 
      image: Calamari_Zucchine, 
      title: 'CALAMARI & ZUCCHINE', 
      description: 'Deep fried squid & courgettes served with homemade mayonnaise', 
      price: '£12.90' 
    },
    { 
      id: 3, 
      image: pizzalogo, 
      title: 'POLPO ALLA GRIGLIA', 
      description: 'Grilled octopus served on a bed of anchovies & garlic salad & homemade chilli sauce', 
      price: '£14.90' 
    },
    { 
      id: 4, 
      image: Gamberoni_Pepe, 
      title: 'GAMBERONI AL PEPE', 
      description: 'Pan fried king prawns in a black pepper sauce served on a bed of sourdough bread', 
      price: '£14.90' 
    }
  ],
  Pizza: [
    { 
      id: 1, 
      image: MARINARA, 
      title: 'MARINARA', 
      description: 'Tomato, oregano, garlic, basil, BIO EVO olive oil', 
      price: '£7.50' 
    },
    { 
      id: 2, 
      image: MARGHERITA, 
      title: 'MARGHERITA', 
      description: 'Tomato, mozzarella, basil, BIO EVO olive oil', 
      price: '£9.50' 
    },
    { 
      id: 3, 
      image: Vegetariana, 
      title: 'VEGETARIANA', 
      description: 'Tomato, mozzarella, aubergines, peppers, courgettes, basil, BIO EVO olive oil', 
      price: '£13.90' 
    },
    { 
      id: 4, 
      image: FORMAGGI, 
      title: '4 FORMAGGI', 
      description: 'Mozzarella, dolcelatte, parmigiana, scamorza, basil, BIO EVO olive oil', 
      price: '£14.90' 
    },
    { 
      id: 5, 
      image: BUFALINA, 
      title: 'BUFALINA', 
      description: 'Tomato, buffalo mozzarella, basil, BIO EVO olive oil', 
      price: '£14.90' 
    },
    { 
      id: 6, 
      image: CALABRESE, 
      title: 'CALABRESE', 
      description: 'Tomato, mozzarella, aubergines, spicy salami, ricotta cheese, basil, BIO EVO olive oil', 
      price: '£15.90' 
    },
    { 
      id: 7, 
      image: Calzone_Napoli, 
      title: 'CALZONE NAPOLI', 
      description: 'Tomato, mozzarella, ricotta cheese, salami, ham, basil, BIO EVO olive oil', 
      price: '£15.90' 
    },
    { 
      id: 8, 
      image: PROSCIUTTO_E_FUNGHI, 
      title: 'PROSCIUTTO E FUNGHI', 
      description: 'Tomato, mozzarella, ham, mushrooms, basil, BIO EVO olive oil', 
      price: '£15.90' 
    },
    { 
      id: 9, 
      image: CAPRICCIOSA, 
      title: 'CAPRICCIOSA', 
      description: 'Tomato, mozzarella, mushrooms, ham, artichoke, olives, basil, BIO EVO olive oil', 
      price: '£16.85' 
    },
    { 
      id: 10, 
      image: la_meta , 
      title: 'LA META\' E META\'', 
      description: 'Tomato, mozzarella, spicy salami, parma ham, ham, ricotta cheese, basil, BIO EVO olive oil', 
      price: '£16.85' 
    },
    { 
      id: 11, 
      image: Napoleona, 
      title: 'NAPOLETANA', 
      description: 'Tomato, mozzarella, anchovies, olives, capers, basil, BIO EVO olive oil', 
      price: '£15.90' 
    },
    { 
      id: 12, 
      image: Diavola, 
      title: 'DIAVOLA', 
      description: 'Tomato, mozzarella, spicy salami, basil, BIO EVO olive oil', 
      price: '£14.90' 
    },
    { 
      id: 13, 
      image: SALSICCIA, 
      title: 'SALSICCIA E FRIARIELLI', 
      description: 'Mozzarella, sausages, friarielli, smoked scamorza, BIO EVO olive oil', 
      price: '£16.90' 
    },
    { 
      id: 14, 
      image: pizzadavalter, 
      title: 'PIZZA DA VALTER', 
      description: 'Mozzarella, cherry tomatoes, rocket, parma ham, burrata, shaved parmesan, basil oil', 
      price: '£20.90' 
    },
    // { 
    //   id: 15, 
    //   image: pizzalogo, 
    //   title: 'Caproni', 
    //   description: 'Mozzarella, Goat Cheese, Mushrooms, Cherry tomato, Rocket', 
    //   price: '£18.85' 
    // }
  ],
  Pasta: [
    { 
      id: 1, 
      image: Tagliatelle_Con_Ragu, 
      title: 'TAGLIATELLE CON RAGU', 
      description: 'Fresh tagliatelle with Bolognese sauce', 
      price: '£16.90' 
    },
    { 
      id: 2, 
      image: pizzalogo, 
      title: 'TAGLIATELLE POMODORINI E MOZZARELLA', 
      description: 'Fresh tagliatelle with cherry tomatoes sauce & melted mozzarella topped with homemade basil oil', 
      price: '£14.90' 
    },
    { 
      id: 3, 
      image: Gnocchi_Alla_Sorrentina, 
      title: 'GNOCCHI ALLA SORRENTINA', 
      description: 'Homemade gnocchi cooked in tomato sauce & baked with mozzarella & basil oil', 
      price: '£14.90' 
    },
    { 
      id: 4, 
      image: Scialatielli_Alla_Carbonara, 
      title: 'SCIALATIELLI ALLA CARBONARA', 
      description: 'Fresh scialatielli, pancetta, egg yolk, parmesan, pecorino & black pepper', 
      price: '£16.90' 
    },
    { 
      id: 5, 
      image: Lasagna_Bolognese, 
      title: 'LASAGNA BOLOGNESE', 
      description: '', 
      price: '£16.90' 
    },
    { 
      id: 6, 
      image: pizzalogo, 
      title: 'LINGUINE AL FRUTTI DI MARE', 
      description: 'Fresh linguine with fresh seafood, cherry tomatoes & white wine sauce', 
      price: '£20.90' 
    },
    // { 
    //   id: 7, 
    //   image: Caproni_Penne, 
    //   title: 'Caproni Penne', 
    //   description: 'Penne pasta, goat cheese, cherry tomatoes, black olives, aubergine, fresh basil', 
    //   price: '£15.90' 
    // }
  ],
  Risotto: [
    { 
      id: 1, 
      image: Risotto_Porcini_E_Carciofi, 
      title: 'RISOTTO PORCINI E CARCIOFI', 
      description: 'Porcini mushrooms, artichokes & truffle oil risotto', 
      price: '£16.90' 
    },
    { 
      id: 2, 
      image: Risotto_Pescatora, 
      title: 'RISOTTO PESCATORA', 
      description: 'Fresh seafood risotto', 
      price: '£21.55' 
    }
  ],
  Main_Courses: [
    { 
      id: 1, 
      image: Grilled_Chicken_Breast, 
      title: 'GRILLED CHICKEN BREAST', 
      description: 'Served with mashed potatoes & porcini sauce', 
      price: '£16.90' 
    },
    { 
      id: 2, 
      image: pizzalogo, 
      title: 'CHILLI CHICKEN', 
      description: 'Grilled chicken breast marinated in chilli sauce served with herb tapenade & fresh salad leaves', 
      price: '£16.90' 
    },
    { 
      id: 3, 
      image:Salmone_Alla_Griglia, 
      title: 'SALMONE ALLA GRIGLIA', 
      description: 'Grilled salmon served on a bed of smoked aubergine sauce & blanched cherry tomatoes & asparagus', 
      price: '£21.85' 
    },
    { 
      id: 4, 
      image: pizzalogo, 
      title: 'SPIGOLA NAPOLITANA', 
      description: 'Pan fried wild seabass fillets served on a bed of artichoke cream & Napolitana sauce (capers, black olives, cherry tomatoes)', 
      price: '£21.75' 
    },
    { 
      id: 5, 
      image: PEPATA_DI_COZZE, 
      title: 'PEPATA DI COZZE', 
      description: 'Wild mussels cooked in a white wine sauce', 
      price: '£16.85' 
    },
    { 
      id: 6, 
      image:Veal_Milanese, 
      title: 'VEAL MILANESE', 
      description: 'Breaded veal served with chips & mix salad', 
      price: '£21.75' 
    },
    { 
      id: 7, 
      image: pizzalogo, 
      title: 'SCOTTISH FILLET STEAK', 
      description: 'Served with rosemary new potatoes & wilted baby spinach', 
      price: '£32.50' 
    }
  ]
};

  // Continue adding other categories such as "Pizza", "Appetizers", "Cold Starters", "Hot Starters", "Pasta", "Risotto", and "Main Courses" following the same format.


export default PizzaDaValterData;
