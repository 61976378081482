// import pizzalogo from "../../../assets/Images/Home/PizzaLogo.png";
import Chocolate_Fondant from "../../../../src/assets/NewMenu/Dessets/Dessets_menu/Chocolate Fondant.jpeg"
import Home_made_tiramisu from "../../../assets/NewMenu/Dessets/Dessets_menu/Home made tiramisu.jpg"
import Cannolo_Siciliano from "../../../assets/NewMenu/Dessets/Dessets_menu/Cannolo Siciliano.jpg"
import Panna_cotta from "../../../assets/NewMenu/Dessets/Dessets_menu/Panna cotta.jpg"
import  MIXED_ICE_CREAM from "../../../assets/NewMenu/Dessets/MIXED_ICE_CREAM.jpeg"
import PIZZA_NUTELLA from "../../../assets/NewMenu/Dessets/Dessets_menu/PIZZA NUTELLA.jpeg"
const pizzalogo = "https://fakeimg.pl/600x600?text=pizzadavalter";
const DessetsDatas = {

  
  Dessert_Menu: [
    {
      id: 1,
      image: Home_made_tiramisu,
      title: 'HOMEMADE TIRAMISU',
      description: '',
      price: '£6.50'

    },
    {
      id: 2,
      image: Chocolate_Fondant,
      title: 'CHOCOLATE FONDANT',
      description: 'with vanilla ice cream',
      price: '£6.50'
    },
    {
      id: 3,
      image: Panna_cotta,
      title: 'PANNA COTTA',
      description: '',
      price: '£6.50'
    },
    {
      id: 4,
      image: PIZZA_NUTELLA,
      title: 'PIZZA NUTELLA',
      description: '',
      price: '£7.95'
    },
    {
      id: 5,
      image: Cannolo_Siciliano,
      title: 'CANNOLO SICILIANO',
      description: '',
      price: '£6.00'
    },
    {
      id: 6,
      image: pizzalogo,
      title: 'Sorbet',
      description: '',
      price: '1* £3.00 , 2*£4.50 , 3*£6.50'
    },
    {
      id: 7,
      image: pizzalogo,
      title: 'AFFOGATO',
      description: '',
      price: '£5.95'
    },
    {
      id: 8,
      image: MIXED_ICE_CREAM,
      title: 'MIXED ICE CREAM',
      description: '',
      price: '1*2.50 , 2*4.50 , 3*6.00'
    }
  ],
  Coffee: [
    {
      id: 1,
      image: pizzalogo,
      title: 'AMERICANO',
      description: '',
      price: '£3.00'
    },
    {
      id: 2,
      image: pizzalogo,
      title: 'CAPPUCCINO',
      description: '',
      price: '£3.20'
    },
    {
      id: 3,
      image: pizzalogo,
      title: 'ESPRESSO',
      description: '',
      price: '£2.80'
    },
    {
      id: 4,
      image: pizzalogo,
      title: 'DOUBLE ESPRESSO',
      description: '',
      price: '£3.00'
    },
    {
      id: 5,
      image: pizzalogo,
      title: 'FLAT WHITE',
      description: '',
      price: '£3.00'
    },
    {
      id: 6,
      image: pizzalogo,
      title: 'HOT CHOCOLATE',
      description: '',
      price: '£3.20'
    },
    {
      id: 7,
      image: pizzalogo,
      title: 'LATTE',
      description: '',
      price: '£3.20'
    },
    {
      id: 8,
      image: pizzalogo,
      title: 'MOCHA',
      description: '',
      price: '£3.20'
    }
  ],
  Tea: [
    {
      id: 1,
      image: pizzalogo,
      title: 'CHAMOMILE TEA',
      description: '',
      price: '£2.80'
    },
    {
      id: 2,
      image: pizzalogo,
      title: 'ENGLISH BREAKFAST',
      description: '',
      price: '£2.80'
    },
    {
      id: 3,
      image: pizzalogo,
      title: 'EARL GREY',
      description: '',
      price: '£2.80'
    },
    {
      id: 4,
      image: pizzalogo,
      title: 'SUPER FRUIT TEA',
      description: '',
      price: '£2.80'
    },
    {
      id: 5,
      image: pizzalogo,
      title: 'GREEN TEA',
      description: '',
      price: '£2.80'
    },
    {
      id: 6,
      image: pizzalogo,
      title: 'FRESH MINT TEA',
      description: '',
      price: '£2.80'
    },
    {
      id: 7,
      image: pizzalogo,
      title: 'PEPPERMINT TEA',
      description: '',
      price: '£2.80'
    }
  ],
  As_A_Digestive: [
    {
      id: 1,
      image: pizzalogo,
      title: 'AMARETTO DISARONNO',
      description: '',
      price: '£4.50'
    },
    {
      id: 2,
      image: pizzalogo,
      title: 'COURVOISIER VS',
      description: '',
      price: '£5.50'
    },
    {
      id: 3,
      image: pizzalogo,
      title: 'FRANGELICO',
      description: '',
      price: '£5.50'
    },
    {
      id: 4,
      image: pizzalogo,
      title: 'JAGERMEISTER',
      description: '',
      price: '£5.50'
    },
    {
      id: 5,
      image: pizzalogo,
      title: 'LIMONCELLO',
      description: '',
      price: '£5.00'
    },
    {
      id: 6,
      image: pizzalogo,
      title: 'SAMBUCA',
      description: '',
      price: '£4.50'
    },
    {
      id: 7,
      image: pizzalogo,
      title: 'VECCHIA ROMAGNA',
      description: '',
      price: '£4.50'
    },
    {
      id: 8,
      image: pizzalogo,
      title: 'VECCHIO AMARO DEL CAPO',
      description: '',
      price: '£4.50'
    },
    {
      id: 9,
      image: pizzalogo,
      title: 'WHITE FALANGHINA GRAPPA',
      description: '',
      price: '£5.00'
    }
  ]
};

export default DessetsDatas;
