import React from "react";
import download from "../../assets/Images/Download/download.png";
import "./Download.css";
import apple from "../../assets/Images/Download/apple.png";
import google from "../../assets/Images/Download/google.png";

const Download = () => {
  return (
    <div className="download-main">
      <h2 className="download-title">Download Pizza Da Valter App!</h2>
      <div className="download">
        <div className="download-left">
          <img src={download} alt="" />
        </div>
        <div className="download-right">
          <div className="download-text">
            <h1>Glad it works simply!</h1>
            <p>1.Download our app on your favorite device: Android or Apple.</p>
            <p>2.Open Pizza Da Valter App and choose your option:</p>
            <ul className="download-text-list">
              <li>Book a Table</li>
              <li>Pick Up</li>
              <li>Delivery(Coming Soon)</li>
            </ul>
            <p>3.Book a Table - Reserve your dine-in for pre-booking.</p>
            <p>
              4.Pick Up - Select your favorite pizza order. You will be notified
              by the app. Get a 10% discount.
            </p>
            {/* <p>
              5.Delivery(Coming Soon) - Order your pizza. Confirm with the delivery guy’s
              call. You will be notified by the app.
            </p> */}
          </div>
          <div className="download-icon">
            <a
              href="https://apps.apple.com/lk/app/pizzadavalter/id6737979183"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={apple}
                alt="Apple Store Link"
                style={{ cursor: "pointer" }} // Pointer cursor to indicate clickability
              />
            </a>

            <a>
              <img
                src={google}
                alt=""
                style={{ filter: "grayscale(100%)", opacity: 0.5 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
